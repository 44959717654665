import request from '@/utils/request.js'

// 公共接口

/**
 * 获取全球区域信息
 */
export function getWordArea() {
  return request({
    url: '/base/worldArea',
    method: 'get'
  })
}


//paypay支付
export function payByPaypal (
  {
    orderNumbers
  }
) {
  return request({
    url:'/order/payByPaypal',
    method:'post',
    data:{
      orderNumbers
    }
  })
}


// 订单产品积分抵扣

export function integrateDiscount (
  {
    product
  }
) {
  return request({
    url:'/order/integrateDiscount',
    method:'post',
    data:{
      product
    }
  })
}

//余额支付
export function payByBalance (
  {
    orderNumbers
  }
) {
  return request({
    url:'/order/payByBalance',
    method:'post',
    data:{
      orderNumbers
    }
  })
}


//card 支付 获取密钥


export function payByStripe (
  {
    orderNumbers
  }
) {
  return request({
    url:'/order/payByStripe',
    method:'post',
    data:{
      orderNumbers
    }
  })
}


//变更信用卡



export function syncCreditCard (
  {
    paymentIntentId
  }
) {
  return request({
    url:'/user/syncCreditCard',
    method:'PATCH',
    data:{
      paymentIntentId
    }
  })
}




//云支付获取密钥
export function payByAirwallex (
  {
    orderNumbers
  }
) {
  return request({
    url:'/order/payByAirwallex',
    method:'POST',
    data:{
      orderNumbers
    }
  })
}