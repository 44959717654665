import request from '@/utils/request.js'

// 获取用户积分信息
export function getUserIntegrateInfo() {
  return request({
    url: '/integrate/userIntegrate',
    method: 'get'
  })
}

// 获取用户积分详情
export function getUserIntegrateList(page) {
  return request({
    url: '/integrate/list',
    method: 'get',
    params: {
      page
    }
  })
}