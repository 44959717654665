import { getUserInfo } from "@/api/account";

const user = {
  namespaced: true,
  state: {
    userInfo: {},
    coins: "",
    usd: "",
  },
  mutations: {
    saveUserInfo(state, userInfo) {
      state.coins = userInfo.rule.split("|")[0];
      state.usd = userInfo.rule.split("|")[1];
      state.userInfo = userInfo;
    },
  },
  actions: {
    async accountAction({ commit }) {
      // 获取用户信息
      const { data: userInfo } = await getUserInfo();
      const code = sessionStorage.getItem("setStoreCode");
      if (!!code && code !== userInfo.defaultStoreCode) {
        sessionStorage.setItem("setStoreCode", userInfo.defaultStoreCode);
      }
      commit("saveUserInfo", userInfo);
    },
  },
};

export default user;
