import { createI18n } from 'vue-i18n';

import en from "@/locale/lang/en-US";
import zh from "@/locale/lang/zh-CN";

const messages = {
    'zh-CN': zh,
    'en-US': en,
}

const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: 'en-US',
    fallbackLocale:'en-US',
    messages
});

export default i18n;