import { createRouter, createWebHistory } from "vue-router"
import main from "@/components/main/index"
// import clipartCategroies from '@/views/clipartCategroies/index.vue'

const routes = [
  {
    path: "/",
    redirect: "/Dashboard",
    component: main,
    children: [
      {
        path: "/Dashboard",
        name: "Dashboard",
        icon: "icon-Dashboard iconfont",
        component: () => import("@/views/Dashboard/index.vue"),
      },
      {
        path: "/Coins",
        name: "Coins",
        component: () => import("@/views/Dashboard/coins.vue"),
      },
      {
        path: "/myAccount",
        name: "My Account",
        icon: "icon-a-Myaccount iconfont",
        component: () => import("@/views/myAccount/index.vue"),
      },
      {
        path: "/fileLibrary",
        name: "File Library",
        icon: "icon-wenjian-1 iconfont",
        component: () => import("@/views/fileLibrary/index.vue"),
      },
      /* {
        path: "/fileLibrary/photo",
        name: "photo",
        component: () => import("@/views/fileLibrary/photo/index.vue"),
      }, */

      // {
      //   path: '/clipartCategroies',
      //   name: 'Clipart Categroies',
      //   icon: "icon-a-Filelibrary iconfont",
      //   component: () => import("@/views/clipartCategroies/index.vue"),
      // },

      {
        path: "/store",
        name: "My Store",
        icon: "icon-a-Mystore iconfont",
        component: () => import("@/views/store/index.vue"),
      },
      {
        path: "/store/settings/:storeCode",
        name: "settings",
        component: () => import("@/views/storeSettings/index.vue"),
      },
      {
        path: "/store/productEdit/:id",
        name: "productEdit",
        component: () => import("@/views/productEdit/index.vue"),
      },
      {
        path: "/store/addNewStore",
        name: "addNewStore",
        component: () => import("@/views/addNewStore/index.vue"),
      },
      {
        path: "/affiliateProgram",
        name: "Affiliate Program",
        icon: "icon-Affiliate iconfont",
        component: () => import("@/views/affiliateProgram/index.vue"),
      },
      {
        path: '/adminDashboard',
        name: 'adminDashboard',
        component: () => import("@/views/adminDashboard/index.vue"),
      },
      {
        path: "/billing",
        name: "Billing",
        icon: "icon-Billing iconfont",
        component: () => import("@/views/billing/index.vue"),
      },
    ],
  },
  {
    path: "/paypaySuccess",
    name: "paypaySuccess",
    component: () => import("@/views/paypaySuccess/index.vue"),
  },
  {
    path: "/paypayError",
    name: "paypayError",
    component: () => import("@/views/paypayError/index.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () => import("@/views/404/index.vue"),
  },
]

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes,
  //切换路由， 滚动条回到顶部
  scrollBehavior () {
    return { top: 0 }
  }
})
export default router
