import { getUserIntegrateInfo } from '@/api/coins'

const coins = {
    namespaced: true,
    state: {
        coinsInfo: {
          integrate: null,
          coin: null,
          usd: null
        }
    },
    mutations: {
        saveCoinsInfo(state, coinsInfo) {
            state.coinsInfo = coinsInfo
        },
    },
    actions: {
        async coinsAction({ commit }) {
            // 获取用户积分信息
            const { data } = await getUserIntegrateInfo()
            const { integrate, rule } = data
            const rules = rule.split('|')
            const coin = rules[0] || 0
            const usd = Number(rules[1]).toFixed(2) || 0
            const coinsInfo = {
              integrate,
              coin,
              usd
            }
            commit('saveCoinsInfo', coinsInfo)
        }
    }
}

export default coins
