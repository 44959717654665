import request from "@/utils/request.js";

/**
 * 获取店铺列表
 */
export function getStoreList() {
  return request({
    url: "/user/storeList",
    method: "get",
  });
}

/**
 * 获取第三方平台列表
 */
export function getPlatformList() {
  return request({
    url: "/base/platformList",
    method: "get",
  });
}

/**
 * 获取店铺详情
 */
export function getStoreDetail(storeCode) {
  return request({
    url: "/user/detailStore",
    method: "get",
    params: {
      storeCode,
    },
  });
}

/**
 * 编辑店铺信息 setting
 */
export function updateStoreInfo(data) {
  return request({
    url: "/user/updateStore",
    method: "patch",
    data,
  });
}

/**
 * 创建店铺
 */
// export function createStore(data) {
//   return request({
//     url: '/user/createStore',
//     method: 'post',
//     data
//   })
// }

/**
 * 删除店铺
 */
export function deleteStore(storeCode) {
  return request({
    url: "/user/destroyStore",
    method: "delete",
    data: {
      storeCode,
    },
  });
}

/**
 * My orders
 * 获取订单列表
 */
export function getOrderList({ storeCode, page, orderNumber, status, sort }) {
  return request({
    url: "/order/list",
    method: "get",
    params: {
      storeCode,
      page,
      orderNumber,
      status,
      sort,
    },
  });
}

/**
 * 获取订单详情
 */
export function getOrderDetail(orderNumber) {
  return request({
    url: "/order/detail",
    method: "get",
    params: {
      orderNumber,
    },
  });
}

/**
 * 删除订单
 */
export function deleteOrder(orderNumber) {
  return request({
    url: "/order/delete",
    method: "delete",
    data: {
      orderNumber,
    },
  });
}

//复制订单

export function copyOrder(orderNumber) {
  return request({
    url: "/order/copy",
    method: "POST",
    data: {
      orderNumber,
    },
  });
}
/**
 * 获取订单各状态
 */
export function getOrderStatus(storeCode) {
  return request({
    url: "/order/oneStoreStat",
    method: "get",
    params: {
      storeCode,
    },
  });
}

/**
 * 获取产品列表 可分页
 */
export function getProductList(params) {
  return request({
    url: "/design/getFinishedProductPaginate",
    method: "get",
    params,
  });
}

/**
 * 获取产品状态的数量
 */
export function getProductStatusCount(storeCode) {
  return request({
    url: "/design/getFinishedProductStateQuantity",
    method: "get",
    params: {
      storeCode,
    },
  });
}

/**
 * 获取用户地址列表
 */
// export function getUserAddressList(params) {
//   return request({
//     url: '/user/addressList',
//     method: 'get',
//     params
//   })
// }

/**
 * 更新地址
 */
// export function updateAddress(data) {
//   return request({
//     url: '/user/updateAddress',
//     method: 'patch',
//     data
//   })
// }

//获取选择成品列表

export function selectFinishedProductList({
  storeCode,
  page,
  perPage,
  designId,
}) {
  return request({
    url: "/design/getSelectFinishedProductList",
    params: {
      storeCode,
      page,
      perPage,
      designId,
    },
  });
}

//获取成品详情
export function finishedProductDetails({ designId }) {
  return request({
    url: "/design/getFinishedProductDetails",
    params: {
      designId,
    },
  });
}

//删除成品
export function finishedProduct({ designId }) {
  return request({
    url: "/design/deleteFinishedProduct",
    method: "delete",
    params: {
      designId,
    },
  });
}

//删除shopify产品
//topic  0=更新  1=下架产品
export function deleteShopifyProduct({ designId, topic }) {
  return request({
    url: "/partner/updateProduct",
    method: "POST",
    data: {
      designId,
      topic: topic ? "downProduct" : "updateProduct",
    },
  });
}

//复制成品
export function copyFinishedProduct({ designId, storeCode }) {
  return request({
    url: "/design/copyFinishedProduct",
    method: "put",
    data: {
      designId,
      storeCode: storeCode || undefined,
    },
  });
}

//获取物流方式

export function availableLogistics({
  factoryCode,
  destinationCode,
  weight,
  siteCode,
  regionId,
  regionType,
}) {
  return request({
    url: "/order/availableLogistics",
    method: "GET",
    params: {
      factoryCode,
      destinationCode,
      weight,
      siteCode,
      regionId,
      regionType,
    },
  });
}

//创建订单

export function create({
  designItems,
  fullName,
  phone,
  country,
  stateOrProvince,
  city,
  postal,
  address1,
  address2,
  transportCode,
  coupon,
  platformOrderId
}) {

  return request({
    url: "/order/create",
    method: "post",
    data: {
      designItems,
      fullName,
      phone,
      country,
      stateOrProvince,
      city,
      postal,
      address1,
      address2,
      transportCode,
      coupon,
      platformOrderId
    },
  });
}

//取消订单
export function cancelOrderNumber({ orderNumber }) {
  return request({
    url: "/order/cancel",
    method: "PATCH",
    data: {
      orderNumber,
    },
  });
}

//切换默认店铺
export function setDefaultStore({ storeCode }) {
  return request({
    url: "/user/setDefaultStore",
    method: "PATCH",
    data: {
      storeCode,
    },
  });
}

//更改地址
/**
 * 
 * @param {
 * 
 fullName
string
收件人-姓名
phone
string
收件人-手机号
stateOrProvince
string
州或省
city
string
城市-如果没有自动补填上一级信息
postal
string
邮编
address1
string
地址1
address2
string
地址2
memo
string
订单备注
orderNumber} param0 
 * @returns 
 */

export function updateDeliveryAddress({
  fullName,
  phone,
  stateOrProvince,
  city,
  postal,
  address1,
  address2,
  memo,
  orderNumber,
}) {
  return request({
    url: "/order/updateDeliveryAddress",
    method: "PATCH",
    data: {
      fullName,
      phone,
      stateOrProvince,
      city: city || stateOrProvince,
      postal,
      address1,
      address2,
      memo: memo || undefined,
      orderNumber,
    },
  });
}
