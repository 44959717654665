import sha256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64'
import CryptoJS from 'crypto-js'

//password
export const encryption = (string) => {
  return Base64.stringify(sha256(string))
}

const obj = {
  'abf9cef7-731d-4fc6-b0a9-8f0b26a8f93b': '06c56a89949d617def52f371c357b6db',
  '94999426-74d0-453f-94f9-86cff8aa5e0d': '949d617def52f371'
}

//验证码手机号码加密
export const encryptionByPhone = (string) => {
  const fkey = CryptoJS.enc.Utf8.parse(obj['abf9cef7-731d-4fc6-b0a9-8f0b26a8f93b']);
  const fiv = CryptoJS.enc.Utf8.parse(obj['94999426-74d0-453f-94f9-86cff8aa5e0d']);
  const enc = CryptoJS.AES.encrypt(string, fkey, {iv: fiv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7,});
  return enc.ciphertext.toString(CryptoJS.enc.Base64);
}
