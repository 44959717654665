import {getRecentlyUsedList, getAlbumTreeList, getPictureList} from '@/api/fileLibrary'
import {ElMessage} from "element-plus";

export default {
    namespaced: true,
    state: {
        //加载中
        loadingAtInit: false,
        loadingRecentlyUsedFiles: false,
        loadingFolder: false,
        //搜索筛选条件
        searchValue: '',
        sortValue: '',
        //使用图片历史记录
        recentlyUsedFiles: [],
        //文件夹数据
        folderCount: 0,
        folderList: [],
        foldersCrumbs: [],
        //文件
        filesCurrent: 1,
        filesCount: 0,
        filesList: [],
        filesLoading: false,
        //上传文件
        uploadLoading: false,
    },
    mutations: {
        setUploadLoading(state, loading) {
            state.uploadLoading = !!loading
        },
        setLoadingInit(state, loading) {
            state.loadingAtInit = !!loading
        },
        setLoadingRecentlyUsedFiles(state, payload) {
            state.loadingRecentlyUsedFiles = !!payload
        },
        setLoadingFolder(state, payload) {
            state.loadingFolder = !!payload
        },

        clearAll(state) {
            state.searchValue = ''
            state.sortValue = ''
            state.recentlyUsedFiles = []
            state.folderCount = 0
            state.folderList = []
            state.foldersCrumbs = []
        },

        setSearchValue(state, payload) {
            if (typeof payload === 'string') {
                state.searchValue = payload
            }
        },
        setSortValue(state, payload) {
            if (typeof payload === 'string') {
                state.sortValue = payload
            }
        },
        setRecentlyUsedFiles(state, payload) {
            if (Array.isArray(payload)) {
                state.recentlyUsedFiles = payload
            }
        },
        setFolderData(state, {count, list}) {
            if (typeof count === 'number' && Array.isArray(list)) {
                state.folderCount = count
                state.folderList = list
            }
        },
        setFoldersCrumbs(state, payload) {
            if (Array.isArray(payload)) {
                state.foldersCrumbs = payload
            }
        },
        //添加数据到导航
        addItemToFoldersCrumbs(state, payload) {
            if (payload && typeof payload === 'object') {
                state.foldersCrumbs.push(payload)
            }
        },
        //删除导航栏数据
        deleteItemAtFoldersCrumbs(state, length) {
            if (length && typeof length === 'number') {
                if (state.foldersCrumbs.length === length) {
                    return
                }
                state.foldersCrumbs.length = length
            }
        },
        //文件
        setFileData(state, {current, count, list}) {
            state.filesCurrent = current
            state.filesCount = count
            state.filesList = list
        },
        setFileLoading(state, loading) {
            state.filesLoading = !!loading
        },
        setFilesCurrent(state, fileCurrent) {
            state.filesCurrent = fileCurrent
        }
    },
    actions: {
        async initData(context) {
            try {
                context.commit('setLoadingInit', true)
                await Promise.all([
                    context.dispatch('getRecentlyUsedFiles'),
                    context.dispatch('getFolderTreeData'),
                    context.dispatch('getImageList'),
                ])
            } finally {
                context.commit('setLoadingInit', false)
            }
        },
        //获取使用记录
        async getRecentlyUsedFiles(context) {
            try {
                context.commit('setLoadingRecentlyUsedFiles', true)
                const res = await getRecentlyUsedList(4)
                if (!!res.data && Array.isArray(res.data)) {
                    context.commit('setRecentlyUsedFiles', res.data)
                }
            } catch (e) {
                ElMessage.error(e)
            } finally {
                context.commit('setLoadingRecentlyUsedFiles', false)
            }
        },
        //获取文件夹树形数据
        async getFolderTreeData(context, isRefresh = false) {
            try {
                context.commit('setLoadingFolder', true)
                const {data: resData} = await getAlbumTreeList()
                const count = resData.count || 0
                let list = []
                if (!!resData?.treeList && Array.isArray(resData?.treeList) && resData?.treeList.length > 0) {
                    list = resData.treeList
                }
                let foldersCrumbs = context.state.foldersCrumbs
                if (!isRefresh) {
                    foldersCrumbs.push({
                        id: 0,
                        parent: 0,
                        name: 'All',
                        img_count: 0,//文件数量
                        count: 0,//文件夹数量
                        sub: list
                    })
                } else {
                    const ids = foldersCrumbs.map(e => e.id)
                    foldersCrumbs = []
                    foldersCrumbs.push({
                        id: 0,
                        parent: 0,
                        name: 'All',
                        img_count: 0,//文件数量
                        count: 0,//文件夹数量
                        sub: list
                    })

                    let data = {
                        id: 0,
                        parent: 0,
                        name: 'All',
                        img_count: 0,//文件数量
                        count: 0,//文件夹数量
                        sub: list
                    }

                    ids.forEach(id => {
                        if (id !== 0) {
                            const item = data.sub?.find(e => e.id === id)
                            // @ts-ignore
                            foldersCrumbs.push(item)
                            // @ts-ignore
                            data = item
                        }
                    })
                }
                context.commit('setFolderData', {count, list})
                context.commit('setFoldersCrumbs', foldersCrumbs)
            } catch (e) {
                ElMessage.error(e)
            } finally {
                context.commit('setLoadingFolder', false)
            }
        },
        //获取图片列表
        async getImageList(context, page) {
            try {
                if (page && typeof page === "number" && page > 0) {
                    context.commit('setFilesCurrent', page)
                }

                context.commit('setFileLoading', true)
                const sortArr = context.state.sortValue?.split('#')
                const {data: resData} = await getPictureList({
                    page: context.state.filesCurrent,
                    albumId: context.getters.foldersCurrentId,
                    keyword: context.state.searchValue,
                    sortValue: sortArr?.[1] || undefined,
                    sortField: sortArr?.[0] || undefined
                })
                context.commit('setFileData', {
                    current: resData.meta.current_page || 1,
                    count: resData.meta.total || 0,
                    list: resData.data.list || []
                })
            } catch (e) {
                ElMessage.error(e)
            } finally {
                context.commit('setFileLoading', false)
            }
        }
    },
    getters: {
        foldersCurrent(state, getters, rootState, rootGetters) {
            return state.foldersCrumbs[state.foldersCrumbs.length - 1]?.sub || []
        },
        fileLibraryLoading(state) {
            return state.loadingRecentlyUsedFiles || state.loadingFolder || state.filesLoading
        },
        foldersCurrentId(state) {
            return state.foldersCrumbs[state.foldersCrumbs.length - 1]?.id
        },
        //返回下载的图片urls
        downLoadFiles(state) {
            return (ids) => {
                return state.filesList.filter(e => ids.includes(e.id))
            }
        }
    }
}
