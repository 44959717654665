import { getStoreList, getPlatformList } from '@/api/myStore.js'
import { ElMessage } from 'element-plus'
const store = {
  namespaced: true,
  state: {
    storeList: [],
    loading:false
  },
  mutations: {
    getStoreList(state, storeList) {
      state.storeList = storeList
    },
    setLoading(state, loading) {
      state.loading = loading
    }
  },
  actions: {
    async storeListAction({ commit }) {
        // 获取店铺列表
        try {
          const { data: storeList } = await getStoreList()

          // const res = await getPlatformList();

          // res.data.forEach(item => {
          //   storeList.forEach(sub => {
          //     if (item.code === sub.platform_code) {
          //       sub.logo = item.logo;
          //     }
          //   });
          // });
          storeList.forEach(e=>{
            e.logo = e?.platform?.logo || ''
          })

          commit('getStoreList', storeList)
          commit('setLoading',true)
        }catch(e) {
          console.log(e);
          ElMessage.error(e)
        }
     




    }
  }
}

export default store
