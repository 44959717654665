import {createStore} from 'vuex'
import FileLibrary from './fileLibrary'
import user from './modules/user'
import address from './modules/address'
import store from './modules/store'
import coins from './modules/coins'

export default createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        FileLibrary,
        user,
        address,
        store,
        coins
    }
})
