import axios from 'axios'
import Store from 'store'

const service = axios.create({
    baseURL: '/proxy/api', // 会根据启动时生产环境的变化而变化, 开发环境时能触发/api 反向代理
    timeout: 60000 // 请求超过五秒算失败
})
// 请求拦截器
service.interceptors.request.use(
    config => {

        let token
        if (process.env.VUE_APP_CUSTOM_ENV === 'development') {
            token = process.env.VUE_APP_TOKEN
        } else {
            token = Store.get('diyToken')
        }

        config.headers = {}

        config.headers['Authorization'] = `Bearer ${token}`

        return config
    },
    error => {
        return Promise.reject(error)
    }
)
// 响应拦截器
service.interceptors.response.use(
    response => {
        const { data } = response
        if (data.code === 400101 || data.code === 400102 || data.code === 400103 || data.code === 400104) {
            //清空缓存
            sessionStorage.clear()
            window.location.replace(decodeURIComponent(`${process.env.VUE_APP_URL_HOME}/login`))
            //需要登陆
            Store.remove('diyToken')
            return Promise.reject(data?.message)
        } else {
            if (data.status === 'success') {
                return Promise.resolve(data)
            } else {
                if (data.code === 400601) {
                    return Promise.reject(data)
                }

                return Promise.reject(data?.message)
            }
        }
    },
    error => {
        if (error.code === 500) {
            return Promise.reject(error?.response?.data?.message)
        } else {
            if (error.code === 400601) {
                return Promise.reject(error?.response?.data)
            }
            if (!!error) {
                if (error?.message) {
                    return Promise.reject(error.message)
                } else {
                    return Promise.reject(error)
                }

            } else {
                return Promise.reject('Network Error')

            }

        }
    }
)

export default service
