import { createApp } from "vue"
import Store from "store"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import ElementPlus from "element-plus"
import "element-plus/dist/index.css"
import i18n from "./locale/index"
import "@/assets/styles/content.scss"
import "@/assets/font/iconfont.css"
import "@/assets/scss/global.scss"
import "@/assets/scss/media.scss"

import * as ElIcons from "@element-plus/icons"
import "./assets/iconfont-avatar/iconfont-avatar.css"

import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
//import Package from '../package.json'

// import * as Sentry from '@sentry/vue'
// import { BrowserTracing } from '@sentry/tracing'


import { encryption, encryptionByPhone } from "@/utils/encryption.js"



// ElementPlus.Dialog.props.lockScroll.default = false

// 获取token
function getSearchString (key, Url) {
  let str = Url
  str = str.substring(1, str.length) // 获取URL中?之后的字符（去掉第一位的问号）
  // 以&分隔字符串，获得类似name=xiaoli这样的元素数组
  let arr = str.split("&")
  let obj = {}
  // 将每一个数组元素以=分隔并赋给obj对象
  for (let i = 0; i < arr.length; i++) {
    let tmp_arr = arr[i].split("=")
    obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1])
  }
  return obj[key]
}

const token = getSearchString("diyToken", window.location.search)
const storeCode = getSearchString("storeCode", window.location.search)
const productName = getSearchString("productName", window.location.search)
const operate = getSearchString("operate", window.location.search)
const storeListStatus = getSearchString("storeListStatus", window.location.search)

//店铺code
if (storeCode) {
  sessionStorage.setItem("setStoreCode", storeCode)
}
//产品名称
if (productName) {
  sessionStorage.setItem("productName", productName)
}
//第一次注册成功用户携带参数
if (operate) {
  sessionStorage.setItem("operate", operate)
}

//是否显示店铺列表
if (storeListStatus) {
  sessionStorage.setItem("storeListStatus", storeListStatus)
}
//token
if (token) {
  Store.set("diyToken", token)
  window.location.replace(window.location.origin + window.location.pathname)
}

const app = createApp(App)
app.use(store)
app.use(router)
app.use(ElementPlus)
app.use(i18n)
app.use(VueViewer)

app.config.globalProperties.$encryption = encryption
app.config.globalProperties.$encryptionByPhone = encryptionByPhone

//register icons
for (const name in ElIcons) {
  app.component(name, ElIcons[name])
}




/*Sentry.init({
  app, // vue实例
  dsn: 'https://f615352468cc41048347991f7d5e546a@sentry.99diy.com/5', // dsn
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router), // router对象
      tracingOrigins: ["localhost", "https://console.99diy.com", /^\//], // 配置一个线上地址
    }),
  ],
  tracesSampleRate: 1.0,
  logErrors: true, // 是否在控制台还显示错误
  release: `${Package.name}@${Package.version}`, // 项目名称@版本号
})*/




app.mount("#application")
