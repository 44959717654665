import request from '@/utils/request.js'


/**
 * 获取用户信息
 */
export function getUserInfo() {
  return request({
    url: '/user/getUserInfo',
    method: 'get'
  })
}

/**
 * 编辑用户信息 
 */
export function editUserInfo({ fullName, phone }) {
  return request({
    url: '/user/editUserInfo',
    method: 'put',
    data: {
      fullName,
      phone
    }
  })
}

/**
 * 更改密码
 */
export function updateUserPassword({ oldPassword, newPassword }) {
  return request({
    url: '/user/changePassword',
    method: 'put',
    data: {
      oldPassword,
      newPassword
    }
  })
}

/**
 * 发送邮箱验证码
 */
export function sendVerifyCode({ email }) {
  return request({
    url: '/user/sendVerifyCode',
    method: 'post',
    data: {
      email
    }
  })
}

/**
 * 更改电子邮箱
 */
export function updateUserEmail(data) {
  return request({
    url: '/user/changeEmail',
    method: 'put',
    data
  })
}

/**
 * 创建地址
 */
export function createAddress(data) {
  return request({
    url: '/user/createAddress',
    method: 'post',
    data
  })
}

/**
 * 获取地址列表
 * @param type 0 为退货地址、收样品的地址；1 为收发票账单的地址
 */
export function getAddressList(type) {
  return request({
    url: '/user/addressList',
    method: 'get',
    params: {
      type
    }
  })
}

/**
 * 更新地址
 */
export function saveAddress(data) {
  return request({
    url: '/user/updateAddress',
    method: 'patch',
    data
  })
}

/**
 * 获取用户钱包信息
 */
export function getUserWalletInfo() {
  return request({
    url: '/wallet/userWallet',
    method: 'get'
  })
}

/**
 * 用户钱包余额充值
 * @param amount 充值金额-两位小数
 * @param type 允许值: paypal,other 默认值为 paypal
 */
 export function addWalletBalance(amount, type="paypal") {
  return request({
    url: '/wallet/rechargeWallet',
    method: 'post',
    data: {
      amount,
      type
    }
  })
}

/**
 * 发送注销账户的验证码
 */
export function sendDeleteUserCode() {
  return request({
    url: '/user/mailDeleteUser',
    method: 'post'
  })
}

/**
 * 通过验证码删除账户
 */
export function verifyDeleteCode(code) {
  return request({
    url: '/user/verifyCodeDeleteUser',
    method: 'delete',
    data: {
      code
    }
  })
}


///user/stripeSecret获取客户端临时秘钥-

export function stripeSecret(

) {
  return request({
    url: '/user/stripeSecret',
    method: 'post',

  })
}

///user/stripeSetupIntent stripe.js请求成功后-

export function stripeSetupIntent(
  {
    paymentMethodId
  }
) {
  return request({
    url: '/user/stripeSetupIntent',
    method: 'post',
    data:{
      paymentMethodId
    }
  })
}

///user/creditCardInfo 已绑定信用卡信息展示



export function creditCardInfo() {
  return request({
    url: '/user/creditCardInfo',
    method: 'get',
  })
}

///user/updateCreditCard 更换

export function updateCreditCard(
  {
    paymentMethodId
  }
) {
  return request({
    url: '/user/updateCreditCard',
    method: 'PATCH',
    data:{
      paymentMethodId
    }
  })
}

//解绑/user/unbindCreditCard
export function unbindCreditCard() {
  return request({
    url: '/user/unbindCreditCard',
    method: 'DELETE',
  })
}