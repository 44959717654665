import request from '@/utils/request.js'

/**
 * 获取最近使用图片列表
 */
export function getRecentlyUsedList(perPage) {
    return request({
        url: '/gallery/getImgUseLogList',
        method: 'get',
        params: {
            perPage
        }
    })
}

/**
 * 获取图库图片列表 可分页
 */
export function getLibraryImageList(params) {
    return request({
        url: '/gallery/getImagesPaginate',
        method: 'get',
        params
    })
}

/**
 * 获取图册树形列表
 */
export function getAlbumTreeList() {
    return request({
        url: '/gallery/getAlbumTreeList',
        method: 'get'
    })
}

/**
 * 创建图册
 * @param {string} name
 * @param {string} icon
 * @param {number} parentId
 * @returns {*}
 */
export function addAlbum(
    {
        name,
        icon,
        parentId
    }
) {
    return request({
        url: '/gallery/addAlbum',
        method: 'post',
        data: {
            name,
            icon,
            parentId
        }
    })
}

/**
 * 编辑图册
 */
export function editAlbum(data) {
    return request({
        url: '/gallery/editAlbum',
        method: 'put',
        data
    })
}

/**
 * 编辑图册（新）
 * @param albumId 图册id
 * @param icon
 * @param name
 * @param parentId
 * @returns {*}
 */
export function editAlbum_New({albumId, icon, name, parentId}) {
    return request({
        url: '/gallery/editAlbum',
        method: 'put',
        data: {
            albumId,
            icon,
            name,
            parentId
        }
    })
}

export function moveAlbum({albumId, parentId}) {
    return request({
        url: '/gallery/editAlbum',
        method: 'put',
        data: {
            albumId,
            parentId
        }
    })
}

/**
 * 删除图册
 */
export function delAlbum(albumId) {
    return request({
        url: '/gallery/deleteAlbum',
        method: 'delete',
        data: {
            albumId
        }
    })
}

/**
 * 编辑图片名称
 */
export function editImgName({imgId, imgName}) {
    return request({
        url: '/gallery/editImgName',
        method: 'put',
        data: {
            imgId,
            imgName
        }
    })
}

/**
 * 获取图库排序选项
 */
export function getLibrarySortSelect() {
    return request({
        url: '/gallery/getSortSelect',
        method: 'get'
    })
}

/**
 * 上传图片
 */
export function uploadImgFile(data) {
    return request({
        url: '/gallery/uploadImages',
        method: 'post',
        data
    })
}

/**
 * 获取图册列表
 */
export function getAlbumList(parentId) {
    return request({
        url: '/gallery/getAlbumList',
        method: 'get',
        params: {
            parentId
        }
    })
}

/**
 * 删除图片 可批量
 */
export function deleteBatchImage(imgId) {
    return request({
        url: '/gallery/deleteImages',
        method: 'delete',
        data: {
            imgId
        }
    })
}

/**
 * 获取图片数据 （分页，筛选）
 */
export function getPictureList(
    {
        page,
        albumId,
        keyword,
        sortField,
        sortValue
    }
) {

    const params = {
        page: page || 1,
        perPage: 8,
        albumId: albumId || 0,
        keyword: keyword || undefined,
    }

    if (sortField && sortValue) {
        params[sortField] = sortValue
    }

    return request({
        url: '/gallery/getImagesPaginate',
        method: 'get',
        params
    })
}

//md5分析图片
export const judgeWhetherTheFileHasBeenUploadedByMd5 = (imgMd5) => {
    return request({
        url: '/gallery/verifyImageMd5',
        method: 'get',
        params: {
            imgMd5
        }
    })
}

//保存图片
export const savePicture = ({md5, larger, albumId, name}) => {
    return request({
        url: '/gallery/saveImage',
        method: 'post',
        data: {
            md5,
            larger,
            albumId,
            name,
        }
    })
}

/**
 * 批量移动图片到文件夹
 * @param {Array<number>} imgId 文件ids
 * @param albumId 文件夹id
 */
export const batchMovePicturesToFolders = ({imgId, albumId}) => {
    return request({
        url: `/gallery/addToAlbum`,
        method: 'put',
        data: {
            imgId,
            albumId
        }
    })
}
