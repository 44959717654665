import { getWordArea } from '@/api/common.js'

const address = {
  namespaced: true,
  state() {
    return {
      areaInfo: {}
    }
  },
  mutations: {
    saveAreaInfo(state, areaInfo) {
      state.areaInfo = areaInfo
    }
  },
  actions: {
    async addressAction({ commit }) {
      // 获取全球区域信息
      const { data: areaInfo } = await getWordArea()
      commit('saveAreaInfo', areaInfo)
    }
  }
}

export default address
