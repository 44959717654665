<script setup>
import { ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import Store from 'store'
import router from '@/router/index'
import logo from '@/assets/logo/logo.jpg'
import logo99 from '@/assets/logo/logo2.jpg'

import { Menu as IconMenu } from '@element-plus/icons'

// 获取用户名
const store = useStore()

store.dispatch('user/accountAction')

const name = computed(() => store?.state?.user?.userInfo?.fullName)
const email = computed(() => store.state.user?.userInfo?.email)
const isAdmin = computed(() => store.state.user?.userInfo?.isAdmin)
//店铺code
// const userInfo = computed(() => store.state.user?.userInfo)
// let storeListStatus = sessionStorage.getItem('storeListStatus')
// if (!!userInfo.defaultStoreCode && !storeListStatus) {
//   sessionStorage.setItem('setStoreCode', userInfo.defaultStoreCode)
// }

let list = router.options.routes[0].children

let active = ref(router.options.history.location)

let route = useRoute()
watch(
  () => route.path,
  () => {
    active.value = route.path
    if (
      route.path.split('/')[2] === 'settings' ||
      route.path.split('/')[2] === 'productEdit' ||
      route.path.split('/')[2] === 'addNewStore'
    ) {
      active.value = '/store'
    }
  }
)

if (route.path !== active.value) {
  if (
    route.path.split('/')[2] === 'settings' ||
    route.path.split('/')[2] === 'productEdit'
  ) {
    active.value = '/store'
  } else if (active.value === '/store?state=1') {
    active.value = route.path
  }
} else {
  if (
    route.path.split('/')[2] === 'addNewStore' ||
    route.path.split('/')[2] === 'productEdit'
  ) {
    active.value = '/store'
  }
}

let isCollapse = ref(false)

let width = ref('230px')

function switchIcon() {
  if (isCollapse.value) {
    width.value = '230px'
  } else {
    width.value = '80px'
  }

  isCollapse.value = !isCollapse.value
}

let bodyClientWidthChange = (e) => {
  one.value = true
  if (e <= 1250) {
    width.value = '80px'
    isCollapse.value = true
  } else {
    width.value = '230px'
    isCollapse.value = false
  }
}

// 监听浏览器的变化
let one = ref(true)
if (one.value) {
  bodyClientWidthChange(document.body.clientWidth)
}

window.addEventListener('resize', (e) => {
  bodyClientWidthChange(document.body.clientWidth)
})

// 获取 token
let token
if (process.env.VUE_APP_CUSTOM_ENV === 'development') {
  token = process.env.VUE_APP_TOKEN
} else {
  token = Store.get('diyToken')
}

// 跳转页面
const handleGoPage = () => {
  window.location.href = process.env.VUE_APP_URL_HOME + `?diyToken=${token}`
}

// 退出登录
const handleExitClick = () => {
  Store.remove('diyToken')
  sessionStorage.removeItem('setStoreCode')
  window.location.href = process.env.VUE_APP_URL_HOME + '?logout=1'
}

// // 获取店铺列表
// store.dispatch("store/storeListAction");
// const storeList = computed(() => store.state.store.storeList);

// // 默认店铺
// const defaultStoreName = ref("");
// watch(
//   () => store.state.store.storeList,
//   () => {
//     defaultStoreName.value = storeList.value.find(
//       (item) => item.is_default === 1
//     ).name;
//   }
// );

// 列表底部的页面跳转功能
const goPage = (page) => {
  active.value = ' '
  if (page === 0) {
    window.location.href = process.env.VUE_APP_URL_HOME + `?diyToken=${token}`
  } else if (page === 1) {
    window.location.href =
      process.env.VUE_APP_URL_HOME + `/Products?diyToken=${token}`
  } else if (page === 2) {
    window.location.href =
      process.env.VUE_APP_URL_HOME + '/mockup-generator' + `?diyToken=${token}`
  }
}

let routerLink = useRouter()

let pushIndex = () => {
  // window.location.href = process.env.VUE_APP_URL_HOME;
  routerLink.push('/Dashboard')
}

let adminDashboard = () => {
  routerLink.push('/adminDashboard')
}
</script>

<template>
  <div class="flex h100">
    <div class="flex left_box">
      <div class="title_header shrink">
        <el-image
          :src="logo"
          v-if="!isCollapse"
          class="w_h_100"
          @click="pushIndex"
        ></el-image>
        <el-image
          :src="logo99"
          v-else
          class="w_h_100"
          @click="pushIndex"
        ></el-image>
      </div>
      <el-menu
        :default-active="active"
        class="shrink"
        :collapse="isCollapse"
        router
        text-color="rgba(0, 0, 0, 0.6)"
        active-text-color="#FF2379"
        :collapse-transition="false"
        background-color="#fff"
      >
        <!-- 合拢 展开图标 -->
        <div class="icon_close_switch" @click="switchIcon">
          <svg
            t="1638966306186"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="2697"
            width="30"
            height="30"
            v-if="!isCollapse"
          >
            <path
              d="M512.05504 512.000142m-483.55381 0a483.553809 483.553809 0 1 0 967.107619 0 483.553809 483.553809 0 1 0-967.107619 0Z"
              fill="#FFFFFF"
              p-id="2698"
            ></path>
            <path
              d="M512.05504 1023.998293a509.153717 509.153717 0 0 1-362.068026-149.930125A508.38572 508.38572 0 0 1 0.056889 512.000142a508.556386 508.556386 0 0 1 149.987014-362.068026A508.556386 508.556386 0 0 1 512.05504 0.001991a508.556386 508.556386 0 0 1 362.068026 149.987014A508.641719 508.641719 0 0 1 1024.053191 512.000142a508.670163 508.670163 0 0 1-149.930125 361.954249A508.812385 508.812385 0 0 1 512.05504 1023.998293z m0-995.55196a484.122696 484.122696 0 0 0-483.55381 483.553809 484.037363 484.037363 0 0 0 483.55381 483.55381 484.037363 484.037363 0 0 0 483.553809-483.55381 484.094252 484.094252 0 0 0-483.553809-483.553809z"
              fill="#E5E5E5"
              p-id="2699"
            ></path>
            <path
              d="M408.261637 501.987734l-10.012408 10.012408 138.154167 138.182612a14.222171 14.222171 0 1 0 19.91104-19.911039l-118.072463-118.100907 118.18624-118.271573a14.222171 14.222171 0 0 0-19.911039-19.911039l-127.544429 127.459095z"
              fill="#000000"
              fill-opacity="0.5"
              p-id="2700"
            ></path>
          </svg>
          <svg
            t="1638966347122"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="2826"
            width="30"
            height="30"
            v-else
          >
            <path
              d="M512.001138 512.000853m483.55475 0a483.55475 483.55475 0 1 0-967.1095 0 483.55475 483.55475 0 1 0 967.1095 0Z"
              fill="#FFFFFF"
              p-id="2827"
            ></path>
            <path
              d="M512.001138 0.001707a509.154707 509.154707 0 0 1 362.06873 149.930416A508.386708 508.386708 0 0 1 1024.000285 512.000853a508.557375 508.557375 0 0 1-149.987306 362.06873A508.557375 508.557375 0 0 1 512.001138 1024a508.557375 508.557375 0 0 1-362.06873-149.987306A508.642708 508.642708 0 0 1 0.001991 512.000853a508.671152 508.671152 0 0 1 149.930417-361.954952A508.813374 508.813374 0 0 1 512.001138 0.001707z m0 995.553896a484.123638 484.123638 0 0 0 483.55475-483.55475 484.038304 484.038304 0 0 0-483.55475-483.554749 484.038304 484.038304 0 0 0-483.55475 483.554749 484.095193 484.095193 0 0 0 483.55475 483.55475z"
              fill="#E5E5E5"
              p-id="2828"
            ></path>
            <path
              d="M607.944089 521.643504l10.069317-10.040872-138.609547-138.666436a14.222199 14.222199 0 1 0-20.110189 20.110189l118.470914 118.499358-118.470914 118.414025a14.222199 14.222199 0 0 0 20.110189 20.138633l127.999787-127.999787 0.597332-0.568888z"
              fill="#000000"
              fill-opacity="0.5"
              p-id="2829"
            ></path>
          </svg>
        </div>

        <div class="h100 list_box">
          <!-- 列表 -->
          <div class="navigation_scroll">
            <template v-for="item in list" :key="item.path">
              <template v-if="item.icon">
                <!-- //无下一级 -->
                <el-menu-item
                  :index="item.path"
                  v-if="
                    !(
                      !!item.children &&
                      item.children.length > 0 &&
                      Array.isArray(item.children)
                    )
                  "
                >
                  <el-icon><i :class="item.icon"></i></el-icon>
                  <template #title>{{ item.name }}</template>
                </el-menu-item>

                <!-- 有下一级 -->
                <el-sub-menu :index="item.path" v-else>
                  <template #title>
                    <el-icon><icon-menu /></el-icon>
                    <span>{{ item.name }}</span>
                  </template>

                  <template v-for="val in item.children" :key="val.path">
                    <!-- //无下一级 -->
                    <el-menu-item
                      :index="val.path"
                      v-if="
                        !(
                          !!val.children &&
                          val.children.length > 0 &&
                          Array.isArray(val.children)
                        )
                      "
                      >{{ val.name }}</el-menu-item
                    >
                    <!-- 有下一级 -->
                    <!--  <el-sub-menu index="1-4" v-else>
                            <template #title><span>item four</span></template>
                            <el-menu-item index="1-4-1">item one</el-menu-item>
                          </el-sub-menu> -->
                  </template>
                </el-sub-menu>
              </template>
            </template>
          </div>

          <!-- 列表底部 -->
          <div class="bottom_p">
            <el-menu-item index="" class="shrink" @click="goPage(0)">
              <el-icon><i class="icon-a-Homepage iconfont"></i></el-icon>
              <template #title>Home page</template>
            </el-menu-item>

            <el-menu-item index="" class="shrink" @click="goPage(1)">
              <el-icon><i class="icon-a-Productscatalog iconfont"></i></el-icon>
              <template #title>Products catalog</template>
            </el-menu-item>

            <el-menu-item index="" class="shrink" @click="goPage(2)">
              <el-icon><i class="icon-a-Mockupgenerator iconfont"></i></el-icon>
              <template #title>Mockup generator</template>
            </el-menu-item>
          </div>
        </div>
      </el-menu>
    </div>
    <div class="flex-1">
      <div class="header_box">
        <!-- 店铺信息 -->
        <!-- <div class="manage_store">
          <el-dropdown trigger="click">
            <span class="el-dropdown-link">
              <i class="icon-a-Mystore iconfont"></i>
              <span class="name">{{ defaultStoreName }}</span>
              <i class="icon-xiangxiajiantou iconfont"></i>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item v-for="item in storeList" :key="item.id">
                  <div class="store_item">
                    <el-image class="store_item_icon" fit="cover" :src="defaultStore" />
                    <div class="right_content">
                      <div class="right_content_name">{{ item.name }}</div>
                      <div :class="['right_content_active', item.is_authorize ? 'green' : 'gray']">{{ item.is_authorize_word }}</div>
                    </div>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item class="setting" @click="router.push('/store')"><el-icon class="setting_icon"><Setting /></el-icon> Manage my stores</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div> -->
        <!-- 个人信息 -->
        <div class="personal_info">
          <el-dropdown trigger="click">
            <span class="el-dropdown-link">
              <i class="icon-gerenzhongxin iconfont"></i>
              <span class="demonstration">{{ name || email }}</span>
              <i class="icon-xiangxiajiantou iconfont"></i>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="handleGoPage">
                  Go to Homepage
                </el-dropdown-item>
                <el-dropdown-item
                  @click="adminDashboard"
                  v-if="Number(isAdmin) === 1"
                >
                  Admin Dashboard
                </el-dropdown-item>
                <el-dropdown-item @click="handleExitClick">
                  Log Out
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
      <div class="scroll">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
:deep(.el-icon) {
  margin-right: 10px !important;

  & > i {
    font-size: 18px !important;
  }
}

:deep(.el-menu-item) {
  font-size: 14px;
}

.w_h_100 {
  cursor: pointer;
}
.dropdown_css {
  top: 40px !important;
}

.shrink {
  width: v-bind(width) !important;
  //flex-shrink: 1;
}

:deep(.el-menu) {
  //height: 100%;
  position: relative;
  border: none;
  .el-menu-item {
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
    &.is-active {
      font-weight: 600;
      color: #ff2379;
      .el-icon i {
        color: #ff2379;
      }
    }
    &.is-active:hover {
      font-weight: 600;
      color: #ff2379;
      .el-icon i {
        color: #ff2379;
      }
    }
    &:hover {
      color: rgba(0, 0, 0, 0.85);
      background-color: rgba(0, 0, 0, 0);
      font-weight: 500;
    }
    &:hover .el-icon i {
      color: rgba(0, 0, 0, 0.5);
    }
    .el-icon {
      margin-right: 15px;
      i {
        font-size: 20px;

        color: rgba(0, 0, 0, 0.25);
      }
    }
  }
  .list_box {
    display: flex;
    flex-direction: column;
    background-color: white;
    height: calc(100vh - 65px);
    border: 1px solid rgba(0, 0, 0, 0.1);
    //上面盒子
    .navigation_scroll {
      overflow-y: auto;
      overflow-x: hidden;
      height: 567px;
    }
    //下面盒子
    .bottom_p {
      margin-top: auto;
      border-top: 1px solid #eee;
    }
  }

  .switch {
    font-size: 12px;
    cursor: pointer;
    z-index: 3;
  }
  .icon_close_switch {
    width: 30px;
    height: 30px;
    // border: 2px solid #eee;
    position: absolute;
    line-height: 28px;
    text-align: center;
    border-radius: 50%;
    right: -15px;
    top: 40px;
    background: #fff;
    z-index: 2;
    cursor: pointer;
    .iconfont {
      font-size: 12px;
    }
  }
}

.left_box {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  z-index: 100;
  .title_header {
    width: 230px;
    text-align: center;
    line-height: 50px;
    height: 65px;
  }
}
.header_box {
  width: 100%;
  position: fixed;
  top: 0;
  height: 65px;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  line-height: 65px;
  padding-right: 40px;
  border-bottom: 2px solid #eee;
  background: #fff;
  // cursor: pointer;
  user-select: none;
  z-index: 99;
  .manage_store {
    margin-right: 35px;
    color: var(--el-text-color-regular);
    .name {
      font-size: 16px;
      padding: 0 10px;
    }
    .icon-xiangxiajiantou {
      vertical-align: baseline;
    }
  }
  .personal_info {
    cursor: pointer;
  }
  .el-dropdown {
    padding-top: 20px;
  }
  .icon-gerenzhongxin {
    font-size: 25px;
  }
  .demonstration {
    font-size: 16px;
    padding: 0 10px;
    vertical-align: super;
  }
  .icon-xiangxiajiantou {
    font-size: 12px;
    vertical-align: super;
  }
}
.scroll {
  margin-top: 65px;
  margin-left: v-bind(width);
  height: calc(100% - 65px);
  overflow: visible;
}
.store_item {
  display: flex;
  height: 72px;
  &_icon {
    margin: 12px 12px 0 0;
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
  .right_content {
    display: flex;
    flex-direction: column;
    padding-right: 60px;
    &_name {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);
    }
    &_active {
      padding: 0 19px;
      height: 24px;
      line-height: 24px;
      border-radius: 15px;
      text-align: center;
    }
    .green {
      color: #fff;
      background-color: #1eab7f;
    }
    .gray {
      color: #000;
      background-color: #eaeaea;
    }
  }
}
.setting {
  height: 72px;
  line-height: 72px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  &_icon {
    font-size: 18px;
  }
}

@media screen and (max-width: 769px) {
  .left_box {
    position: fixed;
    left: 0;
  }
  .scroll {
    margin-left: 80px !important;
  }
}
</style>
